import React from "react";
import { FaDownload } from "react-icons/fa";
import { Image } from "cloudinary-react";

import { trackEvent } from "../../Utils/useGAEventTracker";
import "./messageDetail.css";
import { getCurrentUser } from "./../../Services/authService";
import { useTranslation } from "react-i18next";

const MessageImages = ({ img, showLargeImage }) => {
  const { t } = useTranslation()
  return (
    <div className="theImagesInMessageDetOuterCon">
      <div className="theIconCon" type="btn">
        <a
          onClick={() =>
            trackEvent(
              `${getCurrentUser() && getCurrentUser().id
              } download an image in message details`,
              `https://res.cloudinary.com/captivator/image/upload/fl_attachment/${img.public_id}.${img.format}`,
              "download image from message detail"
            )
          }
          href={`https://res.cloudinary.com/captivator/image/upload/fl_attachment/${img.public_id}.${img.format}`}
        >
          <FaDownload data-tip={t("download image")} />
        </a>
      </div>
      <a
        onClick={() => showLargeImage(img)}
        className="btn theMessageImageContainer"
        data-toggle="modal"
        data-target="#exampleModalShowMsgPic"
      >
        {img.public_id ? (
          <Image
            style={{ objectFit: "cover" }}
            className="theImagesInMessageDet"
            cloudName="dw9gomx53"
            publicId={img.public_id}
            crossOrigin="anonymous"
          />
        ) : (
          <img
            style={{ objectFit: "cover" }}
            crossOrigin="anonymous"
            className="theImagesInMessageDet"
            src={img.url}
          />
        )}
      </a>
    </div>
  );
};

export default MessageImages;
