import React from "react";
import moment from "moment";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl } from "cloudinary-build-url";

import "../SearchListing/searchListing.css";
import "../../components/Home/styleCompo.css";

setConfig({
  cloudName: "dw9gomx53",
});

const SearchListingDisplayDetails = ({
  listings,
  listingFromMap,
  onClickSelectedListing,
  placeholderNumberOfListing,
  onHoverListing,
  selectedItem,
  theSelected,
  onClickSelectedListingImage,
}) => {
  const { t } = useTranslation();

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    const diffInMonth = nowDate.diff(postedDate, "months");
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60) return `${diffInSec} ${t("sec_ago")}`;
    if (diffInMin < 60) return `${diffInMin} ${t("min_ago")}`;
    if (diffInHour < 24) return `${diffInHour} ${t("hr_ago")}`;
    if (diffInDay < 30) return `${diffInDay} ${t("day_ago")}`;
    if (diffInMonth < 12) return `${diffInMonth} ${t("mth_ago")}`;

    return `${diffInYear} ${t("yr_ago")}`;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  if (listings.length === 0)
    return (
      <div className="noAvailableListInfoContainerForSearchList">
        <div className="alert alert-danger">{placeholderNumberOfListing}</div>
      </div>
    );
  return (
    <div className="searchListingDisplayCon">
      <div className="row">
        <div
          style={{ height: "270px" }}
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            {listings.map((list, index) => (
              <li
                key={`${index}`}
                data-target="#carouselExampleSlidesOnly"
                data-slide-to={`${index}`}
                className={index == 0 ? "active" : ""}
              ></li>
            ))}
          </ol>

          <div className="carousel-inner">
            {listings.map((list, index) => (
              <div
                key={`${index}`}
                style={{ width: "100%", height: "250px" }}
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {list.map((listing) => {
                    const src = buildUrl(listing.main_photo.path.url, {
                      transformations: {
                        resize: {
                          type: "scale",
                          width: 900,
                          height: 500,
                        },
                      },
                    });
                    return (
                      <div
                        key={listing.id}
                        style={{
                          width: "24%",
                          margin: "0 0.5%",
                          height: "240px",
                        }}
                        className={
                          listing === listingFromMap
                            ? "searchListingDisplayCardCon1"
                            : "searchListingDisplayCardCon"
                        }
                      >
                        <div
                          onMouseEnter={() => onHoverListing(listing)}
                          id="mapSectionListContainer"
                          className="card"
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItem: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => onClickSelectedListingImage(listing)}
                          >
                            {/* <div
                            className={
                              listing === theSelected && theSelected
                                ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer1'
                                : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                            }
                          >
                            click to see details
                          </div> */}

                            <img
                              crossOrigin="anonymous"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "cover",
                              }}
                              className="searchListingCarouselImages1"
                              src={src}
                              alt="First slide"
                            />
                          </div>
                        </div>

                        <div
                          type="button"
                          onClick={() => onClickSelectedListing(listing)}
                          className="card-body cardBodyContainerForSearchList"
                        >
                          <div className="title_locationCon1">
                            <h6 className="card-title card-title2">
                              <b>
                                {capitalize(
                                  listing.title.length > 12
                                    ? listing.title.slice(0, 12) + ".."
                                    : listing.title
                                )}
                              </b>
                            </h6>
                            <p className="card-text">
                              {t("numberListingInTown", {
                                numberOfBedrooms:
                                  listing.listing_type !== "Land"
                                    ? listing.bedrooms
                                    : "",
                                bedrooms:
                                  listing.listing_type !== "Land"
                                    ? t("Bedrooms")
                                    : "",
                                listingType: capitalize(
                                  t(listing.listing_type)
                                ),
                                city: capitalize(listing.city),
                              })}
                            </p>
                          </div>

                          <div className="price_posted">
                            <h6 className="card-title card-title2">
                              <b>{`${commaSeparateNumber(listing.price)} ${listing.currency
                                }`}</b>
                            </h6>
                            <p className="card-text">
                              {t("Posted")}: {calculateDate(listing.dateposted)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          {listings.length > 1 && (
            <>
              <a
                style={{ zIndex: "2" }}
                className="carousel-control-prev"
                href="#carouselExampleSlidesOnly"
                role="button"
                data-slide="prev"
              >
                <FaChevronLeft className="listingDetailsChevron" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                style={{ zIndex: "2" }}
                className="carousel-control-next"
                href="#carouselExampleSlidesOnly"
                role="button"
                data-slide="next"
              >
                <FaChevronRight className="listingDetailsChevron" />
                <span className="sr-only">Next</span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchListingDisplayDetails;
